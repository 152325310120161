<template>
    <b-modal
      ref="eventLogModal"
      id="event-log-modal"
      :title= "displayModalTitle(this.modalType)"
      size="md"
      ok-only
      scrollable
      ok-title="Închide"
      @ok="hideModal"
      @show="onShow">
      <div v-if="eventLogs && eventLogs.length > 0">
        Există un istoric cu <strong>{{ eventLogs.length }}</strong> {{eventLogs.length > 1 ? 'evenimente' : 'eveniment'}}.
        <ul class="mb-0">
          <li v-for="eventLog in eventLogs" v-bind:key="eventLog.eventLogEntryId" class="mt-1">
            la {{displayAsDate(eventLog.eventOccuredAt)}}, user-ul <user-name-badge :user-name="eventLog.fullName" /> a efectuat acțiunea de <code>{{ displayLogEntryEventType(eventLog.eventType)}}</code> <span v-if="eventLog.payload != null && eventLog.payload != 'null'">cu parametrii <code>{{eventLog.payload}}</code></span>
          </li>
        </ul>
      </div>
      <div v-else>
        {{displayModalEmptyLogsMessage(this.modalType)}}
      </div>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import UserNameBadge from './UserNameBadge.vue';
import { EventLogSubjectType, EventLogEventType } from '../constants';

const { DateTime } = require('luxon');

export default {
  name: 'EventLogModal',
  components: {
    UserNameBadge,
  },
  data() {
    return {
      subjectIdentifier: null,
      modalType: null,
      eventLogs: [],
    };
  },
  methods: {
    ...mapActions([
      'performEventLogsQueryByIdentifier',
    ]),

    displayLogEntryEventType(eventType) {
      if (eventType === EventLogEventType.CreateIssuedInvoice) {
        return 'Creare factură';
      }

      if (eventType === EventLogEventType.IssuedInvoiceDownload) {
        return 'Descărcare factură';
      }

      if (eventType === EventLogEventType.RevertIssuedInvoiceCancellation) {
        return 'Revocare anulare factură';
      }

      if (eventType === EventLogEventType.IssuedInvoiceCancellation) {
        return 'Anulare factură';
      }

      if (eventType === EventLogEventType.ClientOrderFileAttachmentAdd) {
        return 'Adăugare atașament';
      }

      if (eventType === EventLogEventType.ClientOrderFileAttachmentDelete) {
        return 'Ștergere atașament';
      }

      if (eventType === EventLogEventType.ClientOrderFileAttachmentDownload) {
        return 'Descărcare atașament';
      }

      if (eventType === EventLogEventType.ClientOrderStatusChange) {
        return 'Schimbat status comandă';
      }

      return eventType;
    },

    displayModalTitle(type) {
      if (!type) {
        return 'Istoric pentru un tip invalid de date';
      }

      if (type === EventLogSubjectType.Order) {
        return 'Istoric evenimente comandă';
      }
      if (type === EventLogSubjectType.Invoice) {
        return 'Istoric evenimente factură';
      }

      return 'Istoric pentru un tip invalid de date';
    },

    displayModalEmptyLogsMessage(type) {
      if (!type) {
        return 'Nu există istoric pentru un tip invalid de date';
      }

      if (type === EventLogSubjectType.Order) {
        return 'Nu există istoric pentru această comandă';
      }
      if (type === EventLogSubjectType.Invoice) {
        return 'Nu există istoric pentru această factură';
      }

      return 'Nu există istoric pentru un tip invalid de date';
    },

    displayAsDate(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
    },

    showModal(subjectIdentifier, modalType) {
      this.modalType = modalType;
      this.subjectIdentifier = subjectIdentifier;
      this.$refs.eventLogModal.show();
    },
    hideModal() {
      this.$refs.eventLogModal.hide();
    },

    async onShow() {
      let result;
      this.eventLogs = [];

      try {
        result = await this.performEventLogsQueryByIdentifier({
          subjectIdentifier: this.subjectIdentifier,
          subjectType: this.modalType,
        });
      } catch (err) {
        console.error(err);
        result = [];
      }

      this.eventLogs = result;
    },
  },
};
</script>
