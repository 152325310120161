<template>
    <b-modal
      ref="sendInvoiceEmailModal"
      id="invoice-email-modal"
      title="Trimite email cu factura"
      size="md"
      cancel-title="Renunță"
      ok-title="Trimite"
      @ok="onOk"
      @show="onShow">
      <b-form-group label="Destinatar">
        <b-form-input required v-model="invoiceEmailRecipient"></b-form-input>
      </b-form-group>
      <b-form-group label="Mesaj (opțional)">
        <b-form-textarea v-model="invoiceEmailMessage" :rows="3" :max-rows="6"></b-form-textarea>
      </b-form-group>
    </b-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
import { mapActions } from 'vuex';
import isEmail from 'validator/es/lib/isEmail';

import toasts from '@/services/toasts';

export default {
  name: 'SendInvoiceEmailModal',
  data() {
    return {
      invoiceEmailRecipient: null,
      invoiceEmailMessage: null,
      messageMaxLength: 500,
      clientCode: null,
      documentType: null,
      invoiceIndex: null,
      issuingYear: null,
    };
  },
  methods: {
    ...mapActions([
      'performClientEmailQueryByCode',
      'performSendIssuedInvoiceEmail',
    ]),
    showModal(clientCode, documentType, invoiceIndex, issuingYear) {
      this.clientCode = clientCode;
      this.documentType = documentType;
      this.invoiceIndex = invoiceIndex;
      this.issuingYear = issuingYear;
      this.$refs.sendInvoiceEmailModal.show();
    },
    hideModal() {
      this.$refs.sendInvoiceEmailModal.hide();
    },
    showPlaceholder(placeholder) {
      return this.inDisplayMode ? '' : placeholder;
    },
    async onShow() {
      this.clearSendInvoiceEmailModal();

      this.invoiceEmailRecipient = await this.performClientEmailQueryByCode({ partnerCode: this.clientCode });
    },

    clearSendInvoiceEmailModal() {
      this.invoiceEmailRecipient = null;
      this.invoiceEmailMessage = null;
    },

    async onOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      try {
        if (!this.invoiceEmailRecipient) {
          toasts.error('Introdu un destinatar');
          return;
        }
        if (!isEmail(this.invoiceEmailRecipient)) {
          toasts.error('Destinatarul trebuie sa fie un email');
          return;
        }
        if (this.invoiceEmailMessage && this.invoiceEmailMessage.length > this.messageMaxLength) {
          toasts.error(`Mesajul trebuie sa aiba mai putin de ${this.messageMaxLength} caractere`);
          return;
        }

        await this.performSendIssuedInvoiceEmail({
          issuedInvoice: { documentType: this.documentType, number: this.invoiceIndex, issuingYear: this.issuingYear },
          recipient: this.invoiceEmailRecipient,
          message: this.invoiceEmailMessage,
        });

        this.$emit('issuedInvoiceEmailSent');

        toasts.success('Email-ul a fost programat pentru trimitere');
        this.$nextTick(() => this.$refs.sendInvoiceEmailModal.hide());
      } catch (err) {
        toasts.error(`Emailul nu a putut fi trimis. ${err.response}`);
      }
    },
  },
};
</script>
